import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withReducers from '../store/withReducers';
import mpSlice, {
  saveWishpond,
  disconnectHubspot,
  disconnectMailchimp,
  getPreferencesByCompany,
  updateMailchimpList,
  updateMarketingSoftware,
  getConnectedStatus,
} from '../components/marketing-platforms/mpSlice';
import tempDataSlice, {
  fetchAccountStatuses,
  fetchClientReps,
  fetchClients,
  fetchDepartments,
  fetchIndustries,
  fetchMailchimpLists,
  fetchTags
} from '../components/marketing-platforms/tempDataSlice';
import {
  ShowPopup,
  Button,
  Theme,
  Wrapper,
  ButtonsGroup,
  Row, Col,
  Text,
  themeOptions,
  Popup,
} from '@commonsku/styles';
import ErrorBoundary from '../components/ErrorBoundary';
import MPPreferencesPopup from '../components/MPPreferencesPopup';
import { useIdentity } from '../hooks';
import { handleOpenSupportChat } from '../utils';
import { window } from '../global';
import { updateMarketingSoftwareData } from '../reducers/temp';

const redirect_url = `${window.location.protocol}//${window.location.host}/admin_settings.php`;
const selectors = {
  getSyncStarted: ({temp, marketing_platforms}) => {
    if (marketing_platforms.platform_data.sync_started) {
      return marketing_platforms.platform_data.sync_started == '1';
    }
    return temp.sync_started == '1';
  },
  getMarketingSoftware: ({temp}) => temp.marketing_software,
  getHubspotConnected: ({temp}) => temp.hubspot_connected,
  getMailchimpConnected: ({temp}) => temp.mailchimp_connected,
  getMailchimpListId: ({temp}) => temp.mailchimp_list_id,
};

const MarketingSoftwareApp = (props) => {
  const dispatch = useDispatch();
  const identity = useIdentity();

  const marketing_software = useSelector(selectors.getMarketingSoftware);
  const mailchimp_connected = useSelector(selectors.getMailchimpConnected);
  const sync_started = useSelector(selectors.getSyncStarted);

  const [state, setState] = useState({
    marketing_software: marketing_software || '',
  });

  useEffect(() => {
    setState({
      ...state,
      marketing_software: marketing_software || ''
    });
  }, [marketing_software]);

  useEffect(() => {
    dispatch(fetchClientReps({
      'max-results': 4294967296,
      parent_type: identity.company_type,
      parent_id: identity.company_id,
      report: true,
      exclude_default_company_users: true,
    }));
    dispatch(fetchClients());
    dispatch(fetchTags({resource_type: 'CLIENT'}));
    dispatch(fetchIndustries());
    dispatch(fetchAccountStatuses({company_type: 'CLIENT'}));
    dispatch(fetchDepartments({
      'max-results': 4294967296,
      parent_type: 'CLIENT',
    }));
    dispatch(getPreferencesByCompany(identity.company_id));
    if (state.marketing_software === 'MAILCHIMP' && mailchimp_connected) {
      dispatch(fetchMailchimpLists());
    }
  }, []);

  const common_props = {...(sync_started ? {disabled: "disabled"} : {})};
  const handleChangeMarketingSoft = (e) => {
    setState({...state, marketing_software: e.target.value});
    dispatch(updateMarketingSoftware(identity.company_id, e.target.value));
  };

  return (
    <ErrorBoundary>
      <Theme>
        <Wrapper>
          <p className="system-enabled">
            <span><input type='radio' name='marketing_software' value='WISHPOND'
              checked={state.marketing_software == 'WISHPOND'}
              {...common_props}
              onChange={handleChangeMarketingSoft}
            /> Wishpond Integration</span>
            <span><input type='radio' name='marketing_software' value='MAILCHIMP'
              checked={state.marketing_software == 'MAILCHIMP'}
              {...common_props}
              onChange={handleChangeMarketingSoft}
            /> Mailchimp Integration</span>
            <span><input type='radio' name='marketing_software' value='HUBSPOT'
              checked={state.marketing_software == 'HUBSPOT'}
              {...common_props}
              onChange={handleChangeMarketingSoft}
            /> HubSpot Integration</span>
            <span><input type='radio' name='marketing_software' value=''
              checked={!state.marketing_software}
              {...common_props}
              onChange={handleChangeMarketingSoft}
            /> None</span>
          </p>
          {state.marketing_software == 'WISHPOND'
            ? <WishpondView />
            : state.marketing_software == 'MAILCHIMP'
            ? <MailchimpView sync_started={sync_started} />
            : state.marketing_software == 'HUBSPOT'
            ? <HubspotView sync_started={sync_started} />
            : null}
        </Wrapper>
      </Theme>
    </ErrorBoundary>
  );
};

const WishpondView = () => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const wishpond_merchid = useSelector(({ temp }) => temp.wishpond_merchid);
  const wishpond_tracking = useSelector(({ temp }) => temp.wishpond_tracking);
  const wishpond_api = useSelector(({ temp }) => temp.wishpond_api);

  const [state, setState] = useState({
    wishpond_merchid,
    wishpond_tracking,
    wishpond_api
  });

  const onSave = () => dispatch(saveWishpond(identity.company_id, {
    wishpond_merchid: state.wishpond_merchid.trim(),
    wishpond_tracking: state.wishpond_tracking.trim(),
    wishpond_api: state.wishpond_api.trim(),
  }));
  const handleChange = (field) => (e) => setState({...state, [field]: e.target.value});

  const common_styles = {
    width: 200,
    padding:4,
    marginTop: '-5px'
  };
  return (
    <div id="wishpond-settings">
      <ul className="fields">
        <li>
          <label>Wishpond&apos;s Merchant ID:</label>
          <input type="text" style={common_styles} name="wishpond_merchid" value={state.wishpond_merchid} placeholder="merchant Id" onChange={handleChange('wishpond_merchid')} />
        </li>
        <li>
          <label>Wishpond&apos;s Tracking Key:</label>
          <input type="text" style={common_styles} name="wishpond_tracking" value={state.wishpond_tracking} placeholder="tracking key" onChange={handleChange('wishpond_tracking')} />
        </li>
        <li>
          <label>Wishpond&apos;s API Key:</label>
          <input type="text" style={common_styles} name="wishpond_api" value={state.wishpond_api} placeholder="API key" onChange={handleChange('wishpond_api')} />
        </li>
        <li>
          <button id="save_wishpond" onClick={onSave}>Save</button>
        </li>
      </ul>
    </div>
  );
};

const HubspotView = ({ sync_started }) => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const hubspot_connected = useSelector(selectors.getHubspotConnected);

  const onToggleConnect = () => {
    if(hubspot_connected) {
      if (sync_started) {
        alert("Sync has already started, please stop sync and try again.");
        return false;
      }

      const confirmed = window.confirm("Are you sure you want to disconnect?");
      if (confirmed) {
        dispatch(disconnectHubspot(identity.company_id));
      }
      return false;
    }

    return true;
  };

  return (
    <div id="hubspot-settings">
      <ul className="fields">
        <li>
          <ShowPopup popup={HubspotOnboardingPopup} render={({ onClick }) => {
            return <button
              id="hubspot_popup_btn" type="button" size="small"
              onClick={() => {
                const confirmed = onToggleConnect();
                confirmed && onClick();
              }}
            >{hubspot_connected ? 'Disconnect' : 'Connect to'} HubSpot</button>;
          }} />
          <hr style={{marginBottom: 10, marginTop: 20}} />
        </li>
        <li>
          <div id="show-mp-preferences-button">
            <ShowPopup popup={MPPreferencesPopup} render={({ onClick }) => {
              return (
                <button id="configure_mp_preferences"
                  type="button"
                  size="small"
                  onClick={onClick}
                  {...(!hubspot_connected ? {disabled: "disabled"} : {})}
                >{sync_started ? 'View Sync' : 'Configure'}</button>
              );
            }} sync_started={sync_started} />
          </div>
        </li>
          </ul>
    </div>
  );
};

const MailchimpView = ({ sync_started }) => {
  const dispatch = useDispatch();
  const identity = useIdentity();

  const mailchimp_connected = useSelector(selectors.getMailchimpConnected);
  const mailchimp_list_id = useSelector(selectors.getMailchimpListId);
  const mailchimp_lists = useSelector(({ temp, temp_data }) => {
    if (temp_data.mailchimp_lists && temp_data.mailchimp_lists.length > 0) {
      return temp_data.mailchimp_lists;
    }
    return temp.mailchimp_lists;
  });

  const [state, setState] = useState({mailchimp_list_id});

  const onConnectMailchimp = (oauthWin) => {
    return onConnectPlatform(oauthWin, 'mailchimp', function() {
      dispatch(updateMarketingSoftwareData({
        mailchimp_connected: true,
      }));

      dispatch(fetchMailchimpLists());
    }, () => dispatch(getConnectedStatus()));
  };

  const onChangeMailchimpList = (e) => {
    setState({...state, mailchimp_list_id: e.target.value});
    dispatch(updateMailchimpList(identity.company_id, e.target.value));
  };

  const onToggleConnect = () => {
    if(mailchimp_connected) {
      if (sync_started) {
        alert("Sync has already started, please stop sync and try again.");
        return false;
      }

      const confirmed = window.confirm("Are you sure you want to disconnect?");
      if (confirmed) {
        dispatch(disconnectMailchimp(identity.company_id));
      }
      return false;
    }

    showAuthWindow({
      path: `/marketing_platform_oauth.php?platform=mailchimp&redirect_url=${redirect_url}`,
      callback: onConnectMailchimp,
    });
    return true;
  };

  const onFetchMailchimpList = () => dispatch(fetchMailchimpLists());

  return (<>
    <div id="mailchimp-settings">
      <ul className="fields">
        <li>
          <button id="connect_mailchimp" onClick={onToggleConnect}>
            {mailchimp_connected ? 'Disconnect' : 'Connect to'} Mailchimp
          </button>
          <hr style={{marginBottom: 10, marginTop: 20}} />
        </li>
        <li style={{width: 420}}>
          <label>Select Audience:</label>
          <select name="mailchimp_list_id"
            {...(sync_started || !mailchimp_connected ? {disabled: "disabled"} : {})}
            value={state.mailchimp_list_id}
            onChange={onChangeMailchimpList}
          >
            <option value="">Select Audience...</option>
            {mailchimp_lists && mailchimp_lists.length > 0 ? mailchimp_lists.map((v, i) => (
              <option value={v.id} key={'mc_list_'+i}>{v.name}</option>
            )) : null}
          </select>
          <button id="mailchimp_refresh_lists"
            {...(sync_started || !mailchimp_connected ? {disabled: "disabled"} : {})}
            onClick={onFetchMailchimpList}
          >🔄</button>
          <div style={{fontStyle: 'italic', width: 550, marginTop: 10}}>
            <p>Please make sure there is an audience is selected before continuing any further. If you do not see any, please create one in Mailchimp and click resfresh button and select.</p>
          </div>
        </li>
        <li>
          <div id="show-mp-preferences-button">
            <ShowPopup popup={MPPreferencesPopup} render={({ onClick }) => {
              return (
                <button id="configure_mp_preferences"
                  type="button"
                  onClick={onClick}
                  style={{marginRight: 10}}
                  {...(!state.mailchimp_list_id || !mailchimp_connected ? {disabled: "disabled"} : {})}
                >{sync_started ? 'View Sync' : 'Configure'}</button>
              );
            }} sync_started={sync_started} />
          </div>
        </li>
      </ul>
    </div>
  </>);
};

const HubspotOnboardingPopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const hubspot_connected = useSelector(selectors.getHubspotConnected);
  const onConnectHubspot = (oauthWin) => {
    return onConnectPlatform(oauthWin, 'hubspot', function() {
      dispatch(updateMarketingSoftwareData({
        hubspot_connected: true,
      }));
      onClose();
    }, () => dispatch(getConnectedStatus()));
  };

  function connectHubspot() {
    showAuthWindow({
      path: `/marketing_platform_oauth.php?platform=hubspot&redirect_url=${redirect_url}`,
      callback: onConnectHubspot,
    });
  }

  return (
    <Theme>
      <Popup
        width={(10/12 * 100) + "%"}
        style={{ padding: '5px 25px', fontFamily: themeOptions.fonts.join(','), maxWidth: 'fit-content', height: '350px', }}
        onClose={onClose}
        title={`Connect HubSpot`}
        controls={<Button onClick={onClose}>Close</Button>}
      >
        <div className="row" style={{ overflow: 'auto', marginBottom: "100px", fontSize: '1rem', }}>
          <Row style={{paddingTop: '30px'}}>
            <Col xs padded>
              <Text as="p" style={{paddingBottom: '10px', fontSize: '1rem',}}>
                To learn more about HubSpot and special offers available please click &apos;Contact Us&apos; and search for &apos;HubSpot&apos;.
                <br />When prompted, request to speak directly with our support team.
              </Text>
              <ButtonsGroup>
                <Button style={{marginRight: '10px'}} onClick={handleOpenSupportChat} cta>Contact Us</Button>
                <Button onClick={connectHubspot}>{hubspot_connected ? 'Disconnect' : 'Connect'} HubSpot</Button>
              </ButtonsGroup>
            </Col>
          </Row>
        </div>
      </Popup>
    </Theme>
  );
};

const onConnectPlatform = (oauthWin, platform, onSuccess=null, onCheckConnected=null) => {
  try {
    if (
      oauthWin.location.pathname == "/marketing_platform_oauth.php" &&
      oauthWin.location.search.includes(`?platform=${platform}&code=`)
    ) {
      onSuccess && onSuccess();
      if (!oauthWin.closed) { oauthWin.close(); }
      return true;
    } else if(oauthWin.closed) {
      onCheckConnected && onCheckConnected();
      return true;
    }
  } catch(e) {
    console.info(e);
  }

  return false;
};

/**
 * Open a window and attach to events in opened window
 * @param {Object} options
 *
 * Usage:
 * showAuthWindow({
      path: 'https://commonsku.com/oauth',
      callback: (oauthWin) => oauthWin.location.pathname == window.location.pathname,
    });
 */
function showAuthWindow(options) {
  if (!options.path) {
    return null;
  }

  const windowArea = {
    width: Math.floor(window.outerWidth * 0.5),
    height: Math.floor(window.outerHeight * 0.5),
  };

  if (windowArea.width < 1000) { windowArea.width = 800; }
  if (windowArea.height < 800) { windowArea.height = 800; }
  windowArea.left = Math.floor(window.screenX + ((window.outerWidth - windowArea.width) / 2));
  windowArea.top = Math.floor(window.screenY + ((window.outerHeight - windowArea.height) / 8));

  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,
    width=${windowArea.width},height=${windowArea.height},
    left=${windowArea.left},top=${windowArea.top}`;

  options.windowName = options.windowName ||  'ConnectWithOAuth';
  options.windowOptions = options.windowOptions || windowOpts;

  const oauthWin = window.open(options.path, options.windowName, options.windowOptions);
  const oauthInterval = window.setInterval(function() {
    const close = options.callback ? options.callback(oauthWin) : false;
    if (oauthWin.closed || close) {
      window.clearInterval(oauthInterval);
    }
  }, 1000);
}

export default withReducers(MarketingSoftwareApp, {
  [mpSlice.name]: mpSlice.reducer,
  [tempDataSlice.name]: tempDataSlice.reducer,
});
