import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withReducers from '../store/withReducers';
import mpSlice, {
  fetchMPContactsStats,
  fetchMPfieldsMapping,
  fetchSyncStatusDetails,
  selectors
} from '../components/marketing-platforms/mpSlice';
import tempDataSlice from '../components/marketing-platforms/tempDataSlice';
import {
  Button, Theme, Popup, Row, Col,
  TabBar, Tab, themeOptions, Loading,
} from '@commonsku/styles';
import { useIdentity } from '../hooks';
import { default_prefs, MPPrefsTab, ContactsTab } from '../components/marketing-platforms';

function MPPreferencesPopup({ onClose, sync_started }) {
  const dispatch = useDispatch();
  const identity = useIdentity();

  // States
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [canReSync, setCanReSync] = useState(false);

  // Selectors variables
  const marketing_software = useSelector(({ temp }) => temp.marketing_software);

  const loading = useSelector(selectors.loading);
  const sync_state = useSelector(selectors.sync_state);
  const sync_details = useSelector(s => _.get(s, 'marketing_platforms.sync_details', {}));
  const syncing_status = useSelector(selectors.syncing_status);
  const mp_preferences = useSelector(s => {
    return _.get(s, 'marketing_platforms.pereferences', default_prefs);
  });
  const platform_data = useSelector(s => {
    const data = _.get(s, 'marketing_platforms.platform_data', {});
    return {
      'total_contacts': _.get(data, 'total_contacts', 0),
      'total_duplicates': _.get(data, 'total_duplicates', null),
      'total_duplicate_emails_contacts': _.get(data, 'total_duplicate_emails_contacts', null),
      'duplicate_emails': _.get(data, 'duplicate_emails_contacts', []) || [],
      'invalid_emails': _.get(data, 'invalid_emails_contacts', []) || [],
    };
  });

  // functions
  const fetchFields = () => dispatch(fetchMPfieldsMapping(identity.company_id));

  // Memos
  const can_sync = React.useMemo(() => {
    const ind = _.get(mp_preferences, 'industry', []);
    const clsts = _.get(mp_preferences, 'client_status', []);
    const dept = _.get(mp_preferences, 'department', []);

    return (ind && ind.length > 0)
      && (clsts && clsts.length > 0)
      && (dept && dept.length > 0)
    ;
  }, [mp_preferences, marketing_software]);

  const getTabs = () => {
    return [
      {
        label: 'Preferences',
        content: <MPPrefsTab
          mp_preferences={mp_preferences}
          platform_data={platform_data}
          sync_started={sync_started}
          syncing_status={syncing_status}
          sync_state={sync_state}
          marketing_software={marketing_software}
          can_sync={can_sync}
          can_re_sync={canReSync}
          onClose={onClose}
        />,
      }
    ].concat(!sync_started ? [] : [
      {
        label: 'Failed',
        content: <ContactsTab
          key="failed_contacts_tab"
          type={'failed_contacts'}
          action={'FAILED'}
          default_comments={'Invalid Email or Sync timeout'}
        />,
        onClick: function () {
          dispatch(fetchSyncStatusDetails({failed_contacts: 'y', limit: 20, offset: 0,}));
        }
      },
      {
        label: marketing_software === 'HUBSPOT' ? 'Deleted' : 'Archived',
        content: <ContactsTab
          key="archived_contacts_tab"
          type={marketing_software=='HUBSPOT' ? 'deleted_contacts' : 'archived_contacts'}
          action={marketing_software=='HUBSPOT' ? 'DELETED' : 'ARCHIVED'}
          default_comments={marketing_software=='HUBSPOT' ? 'Deleted contact' : 'Archived contact'}
        />,
        onClick: function () {
          const key = marketing_software=='HUBSPOT' ? 'deleted_contacts' : 'archived_contacts';
          dispatch(fetchSyncStatusDetails({[key]: 'y', limit: 20, offset: 0,}));
        }
      },
      {
        label: 'Unsubscribed',
        content: <ContactsTab
          key="unsubscribed_contacts_tab"
          type={'unsubscribed_contacts'}
          action={'UNSUBSCRIBED'}
          default_comments={'Unsubscribed contact'}
        />,
        onClick: function () {
          dispatch(fetchSyncStatusDetails({unsubscribed_contacts: 'y', limit: 20, offset: 0,}));
        }
      }
    ]);
  };

  const tabs = getTabs();

  // init
  useEffect(() => {
    if (sync_started) {
      dispatch(fetchSyncStatusDetails());
    }
    fetchFields();
    dispatch(fetchMPContactsStats({mp_prefs: mp_preferences}));
  }, []);

  // refresh sync details (until 10 interations) until active or failed received
  useEffect(() => {
    const timer = setTimeout(() => {
      if (sync_started) {
        dispatch(fetchSyncStatusDetails());
      } else {
        dispatch(fetchMPContactsStats({mp_prefs: mp_preferences}));
      }
    }, 5000);

    if (!sync_state) {
      if (_.get(sync_details, 'failed', 0) > 0 ||
        _.get(sync_details, 'unsubscribed', 0) > 0 ||
        _.get(sync_details, 'pending', 0) > 0 ||
        _.get(sync_details, 'archived', 0) > 0 ||
        _.get(sync_details, 'deleted', 0) > 0
      ) {
        setCanReSync(true);
      } else {
        setCanReSync(false);
      }
      clearTimeout(timer);
    }

    return () => {
      return timer && clearTimeout(timer);
    };
  }, [sync_started, sync_details, sync_state]);

  return (
    <Theme>
      <Popup
        width={(10/12 * 100) + "%"}
        style={{ padding: '5px 25px', fontFamily: themeOptions.fonts.join(',') }}
        onClose={onClose}
        title={`${marketing_software ? _.upperFirst(_.lowerCase(marketing_software)) : ''} Sync`}
        controls={<Button onClick={onClose}>Close</Button>}
      >
        <div className="row" style={{ overflow: 'auto', marginBottom: "100px", }}>
          <Row>
            <Col xs padded>
              <TabBar padded>
                {tabs.map((tab, index) => <Tab
                  key={'tabs_'+index} size={tabs.length}
                  selected={index == selectedTabIndex}
                  onClick={() => {
                    setSelectedTabIndex(index);
                    let callback = tabs[index].onClick;
                    if(callback) { callback(); }
                  }}>
                  {tab.label}
                </Tab>)}
              </TabBar>
              {loading ? <Loading/> : (tabs[selectedTabIndex] ? tabs[selectedTabIndex].content : null)}
            </Col>
          </Row>
        </div>
      </Popup>
    </Theme>
  );
}

export default withReducers(MPPreferencesPopup, {
  [mpSlice.name]: mpSlice.reducer,
  [tempDataSlice.name]: tempDataSlice.reducer,
});
